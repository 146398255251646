import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker, { CalendarContainer, registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import {  subDays,  format } from 'date-fns'

import './react-datepicker.css';

import './DateRangeInput.css'

registerLocale('pt-BR', ptBR)

const DateRangeInput = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate ? new Date(props.startDate) : new Date());
  const [endDate, setEndDate] = useState(props.endDate ? new Date(props.endDate) : new Date());
  const [lblRange, setLblRange] = useState(props.lblRangeDt ? props.lblRangeDt : "Personalizado");

  const handleChangeRange = (dates) => {
    const [start, end] = dates;
    console.log("handleChangeRange", start, end);
    setStartDate(start);
    setEndDate(end);
    findRange(start, end);
  };

  const findRange = (start, end) => {
    if (start == null || end == null)
      return;

    let lblRange = "";
    let today = new Date();
    today.setHours( 0,0,0,0 );
    let ontem = subDays( today,  1 );

    start.setHours( 0,0,0,0 );
    end.setHours( 0,0,0,0 );

    console.log( "findRange Prev", lblRange, format( start, 'dd/MM/yyyy' ), format( end, 'dd/MM/yyyy' ), format( today, 'dd/MM/yyyy' ), format( ontem, 'dd/MM/yyyy' ) );

    if ( format( start, 'dd/MM/yyyy' ) === format( end, 'dd/MM/yyyy' ) ) {
      if ( format( start, 'dd/MM/yyyy' ) === format( today, 'dd/MM/yyyy' ))
        lblRange = "Hoje";
      else if (format( start, 'dd/MM/yyyy' ) === format( ontem, 'dd/MM/yyyy' ))
        lblRange = "Ontem";
      else
        lblRange = "Personalizado";
    } else if (format( start, 'dd/MM/yyyy' ) === format( ontem, 'dd/MM/yyyy' ) && format( end, 'dd/MM/yyyy' ) === format( today, 'dd/MM/yyyy' ))
      lblRange = "Desde Ontem";
    else if (format( start, 'dd/MM/yyyy' ) === format( subDays( today, 7), 'dd/MM/yyyy' ) && (format( end, 'dd/MM/yyyy' ) === format( today, 'dd/MM/yyyy' )))
      lblRange = "Ult. 7 Dias";
    else if (format( start, 'dd/MM/yyyy' ) === format( subDays( today, 30), 'dd/MM/yyyy' ) && (format( end, 'dd/MM/yyyy' ) === format( today, 'dd/MM/yyyy' )))
      lblRange = "Ult. Mês"; 
    else
      lblRange = "Personalizado"; 

    console.log( "findRange Pos", lblRange, start, end, today, ontem );
    setLblRange(lblRange);
    return;
  };

  const setRange = (lblRange) => {
    setLblRange(lblRange);
    updRange(lblRange);
    setIsShow(false);
  }

  const updRange = (lblRange) => {
    let today = new Date();
    today.setHours( 0,0,0,0 );
    let ontem = subDays( today,  1 );

    if( lblRange === 'Hoje' ) {
      setStartDate( today );
      setEndDate( today );
    } else if ( lblRange === 'Ontem' ) {
      setStartDate( ontem );  
      setEndDate( ontem );  
    } else if ( lblRange === 'Desde Ontem' ) {
      setStartDate( ontem );  
      setEndDate( today );  
    } else if ( lblRange === 'Ult. 7 Dias' ) {
      setStartDate( subDays( today, 7 ) );  
      setEndDate( today );  
    } else if ( lblRange === 'Ult. Mês' ) {
      setStartDate( subDays( today, 30 ) );  
      setEndDate( today );  
    }
  };

  const handleCancelar= () => {
    setStartDate(props.startDate ? new Date(props.startDate) : new Date());
    setEndDate(props.endDate ? new Date(props.endDate) : new Date());
    setLblRange(props.lblRangeDt ? props.lblRangeDt : "Personalizado");
    setIsShow(false);
  };
  const handleSalvar= () => {
    setIsShow(false);
  };

  useEffect(() => {
    handlerClose(); // This will be executed when the state changes
  }, [isShow, startDate, endDate, lblRange]);


  const handlerClose = ( ) => {
    console.log( "handlerClose", isShow, startDate, endDate, lblRange);
    if( !isShow )
      props.onChange( startDate, endDate, lblRange );
  }

  console.log( "DateRangeInput", 
      (startDate? format( startDate, "dd/MM/yyyy"):null), 
      (endDate?format( endDate, "dd/MM/yyyy"):null), 
      lblRange, isShow );

  const MyContainer = ({ className, children }) => {
    return (
      <div className="date-range-input" >
        <Row  >
          <Col className="ranges" style={{ "borderRight": "1px solid gray" }} >
            <ul >
              <li className={lblRange==="Hoje"?"active":""} onClick={() => setRange('Hoje')}> Hoje</li>
              <li className={lblRange==="Ontem"?"active":""} onClick={() => setRange('Ontem')}> Ontem</li>
              <li className={lblRange==="Desde Ontem"?"active":""} onClick={() => setRange('Desde Ontem')}> Desde Ontem</li>
              <li className={lblRange==="Ult. 7 Dias"?"active":""} onClick={() => setRange('Ult. 7 Dias')}> Ult. 7 Dias</li>
              <li className={lblRange==="Ult. Mês"?"active":""} onClick={() => setRange('Ult. Mês')}> Ult. Mês</li>
              <li className={lblRange==="Personalizado"?"active":""} onClick={() => setRange('Personalizado')}> Personalizado</li>
            </ul>
          </Col>
          <Col lg="10" style={{ "width": "500px" }} >
            <CalendarContainer className={className}>
              <div style={{ "borderRight": "1px solid red", "padding": "0px" }} >{children}</div>

            </CalendarContainer>
          </Col>
        </Row>
        <Row className="align-items-center" style={{ "padding": "0px", "marginLeft": "1px", "marginRight": "1px", "borderTop": "1px solid gray" }}>
          <Col xs lg="2" className="py-0 px-0">
          </Col>
          <Col xs lg="6" className="py-0 px-0">
            <h5 className=""> Periodo: {startDate ? startDate.toLocaleDateString() : ""} - {endDate ? endDate.toLocaleDateString() : ""} </h5>
          </Col>
          <Col className="align-self-end py-1 px-1">
            <button type="button" className="btn btn-light" aria-label="Cancelar" onClick={() => handleCancelar() }>
              Cancelar
            </button>
          </Col>
          <Col className="align-self-end py-1 px-1">
            <button type="button" className="btn btn-primary" aria-label="Salvar" onClick={() => handleSalvar()}>
              Salvar
            </button>
          </Col>
        </Row>
      </div>
    );
  };
  return (

    <DatePicker
      locale="pt-BR"
      dateFormat="dd/MM/yyyy"
      selected={startDate}
      onChange={(dates) => handleChangeRange(dates)}
      monthsShown={2}
      onInputClick={() => setIsShow(true)}
      open={isShow}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      calendarContainer={MyContainer}
    />

  );
}

export default DateRangeInput;