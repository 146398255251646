import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const Navbar = ( props ) => {
	const path = useLocation().pathname;
	const { logout } = useAuth0();

	const handleLogout = () => {
	  logout({
		logoutParams: {
		  returnTo: window.location.origin,
		},
	  });
	};
  


	return (
		<>
			<nav id="mainnav-container" className="mainnav">
				<div className="mainnav__inner">

					<div className="mainnav__top-content scrollable-content pb-5">

						<div className="mainnav__profile mt-3 d-flex3">

							<div className="mt-2 d-mn-max"></div>

							<div className="mininav-toggle text-center py-2">
								<img className="mainnav__avatar img-md rounded-circle border" src="/assets/img/profile-photos/1.png" alt="Profile Picture" />
							</div>

							<div className="mininav-content collapse d-mn-max">
								<div className="d-grid">

									<button className="d-block btn shadow-none p-2" data-bs-toggle="collapse" data-bs-target="#usernav" aria-expanded="false" aria-controls="usernav">
										<span className="dropdown-toggle d-flex justify-content-center align-items-center">
											<h6 className="mb-0 me-3">Usuário da Engel</h6>
										</span>
										<small className="text-muted">Administrator</small>
									</button>

									<div id="usernav" className="nav flex-column collapse">
										<a href="#" className="nav-link d-flex justify-content-between align-items-center">
											<span><i className="demo-pli-mail fs-5 me-2"></i><span className="ms-1">Messages</span></span>
											<span className="badge bg-danger rounded-pill">14</span>
										</a>
										<a href="#" className="nav-link">
											<i className="demo-pli-male fs-5 me-2"></i>
											<span className="ms-1">Profile</span>
										</a>
										<a href="#" className="nav-link">
											<i className="demo-pli-gear fs-5 me-2"></i>
											<span className="ms-1">Settings</span>
										</a>
										<a href="#" className="nav-link">
											<i className="demo-pli-computer-secure fs-5 me-2"></i>
											<span className="ms-1">Lock screen</span>
										</a>
										<a href="#" className="nav-link">
											<i className="demo-pli-unlock fs-5 me-2"></i>
											<span className="ms-1">Logout</span>
										</a>
									</div>

								</div>
							</div>

						</div>
						<div className="mainnav__categoriy py-3">
							<h6 className="mainnav__caption mt-0 px-3 fw-bold">Operações</h6>
							<ul className="mainnav__menu nav flex-column">

								<li className="nav-item has-sub">

									<a href="#" className={ "mininav-toggle nav-link " + (path.includes( "/via/")?"active":"" ) } >
										<i className="demo-pli-home fs-5 me-2"></i>
										<span className="nav-label ms-1">Via Varejo</span>
									</a>

									<ul className={ "mininav-content nav "+ (path.includes( "/via/")?"active":"collapse" ) }>
										<li className="nav-item">
											<a href="/via/dashboard" className={ (path==='/via/dashboard'?"nav-link active":"nav-link" )}>Consultas</a>
										</li>
										<li className="nav-item">
											<a href="/via/tasks/1" className={ (path==='/via/tasks/1'?"nav-link active":"nav-link" )}>Tarefas</a>
										</li>
										<li className="nav-item">
											<a href="/via/actions/1" className={ (path==='/via/actions'?"nav-link active":"nav-link" )}>Ações</a>
										</li>
									</ul>

								</li>
								<li className="nav-item has-sub">

									<a href="#" className="mininav-toggle nav-link collapsed">
										<i className="demo-pli-split-vertical-2 fs-5 me-2"></i>
										<span className="nav-label ms-1">Graacc</span>
									</a>

									<ul className="mininav-content nav collapse">
										<li className="nav-item">
											<a href="/graacc/dashboard" className="nav-link">Dashboard</a>
										</li>
									</ul>

								</li>

							</ul>
						</div>
						<div className="mainnav__categoriy py-3">
							<h6 className="mainnav__caption mt-0 px-3 fw-bold">Configurações</h6>
							<ul className="mainnav__menu nav flex-column">

								<li className="nav-item has-sub">

									<a href="#" className="mininav-toggle nav-link collapsed"><i className="demo-pli-boot-2 fs-5 me-2"></i>
										<span className="nav-label ms-1">Cadastros</span>
									</a>

									<ul className="mininav-content nav collapse">
										<li className="nav-item">
											<a href="./ui-elements-buttons.html" className="nav-link">Clientes</a>
										</li>
										<li className="nav-item">
											<a href="./ui-elements-cards.html" className="nav-link">Canais</a>
										</li>
										<li className="nav-item">
											<a href="./ui-elements-dropdowns.html" className="nav-link">Frases</a>
										</li>
										<li className="nav-item">
											<a href="./ui-elements-typography.html" className="nav-link">Remarketing</a>
										</li>
									</ul>

								</li>
								<li className="nav-item has-sub">

									<a href="#" className="mininav-toggle nav-link collapsed"><i className="demo-pli-pen-5 fs-5 me-2"></i>
										<span className="nav-label ms-1">Monitoração</span>
									</a>

									<ul className="mininav-content nav collapse">
										<li className="nav-item">
											<a href="./forms-general.html" className="nav-link">Servidores</a>
										</li>
										<li className="nav-item">
											<a href="./forms-tags.html" className="nav-link">Serviços</a>
										</li>

									</ul>

								</li>
								<li className="nav-item has-sub">

									<a href="#" className="mininav-toggle nav-link collapsed"><i className="demo-pli-receipt-4 fs-5 me-2"></i>
										<span className="nav-label ms-1">Segurança</span>
									</a>

									<ul className="mininav-content nav collapse">
										<li className="nav-item">
											<a href="./tables-static-tables.html" className="nav-link">Usuários</a>
										</li>
										<li className="nav-item">
											<a href="./tables-gridjs.html" className="nav-link">Grupos</a>
										</li>
									</ul>

								</li>

							</ul>
						</div>


					</div>
					<div className="mainnav__bottom-content border-top pb-2">
								<a href="#" className="nav-link " aria-expanded="false" onClick={ handleLogout }>
									<i className="demo-pli-unlock fs-5 me-2"></i>
									<span className="nav-label ms-1">Logout</span>
								</a>
					</div>

				</div>
			</nav>
		</>
	);
};

export default Navbar;
