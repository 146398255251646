import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { useLocation } from 'react-router-dom';
import { PageLoader } from "./page-loader";
import axios from "axios";

let axiosInstance = null;

export const AuthenticationGuard = ( { component }) => {
  const path = useLocation();
  console.log( "AuthenticationGuard");
  if( axiosInstance === null ) {
    console.log( "AuthenticationGuard - axiosInstance")
    axiosInstance = axios.create({
      withCredentials: true
     });
  };
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component path={path.pathname} axiosInstance={axiosInstance} />;
};
