import { useEffect } from "react"

const useScript = ( id, url, integrity, async = false, defer=true, crossOrigin = "anonymous") => {
  useEffect(() => {
    if (document.getElementById(id)) {
      console.log( "Document already has script with id: " + id );
      return;
    }
    console.log( "Loading script id: " + id );
    const script = document.createElement("script")
    script.id = id 
    script.src = url

    script.async = async
    script.defer = defer

    if (integrity) {
      script.integrity = integrity
    }

    //script.crossOrigin = crossOrigin

    document.body.appendChild(script)

    /* return () => {
      document.body.removeChild(script)
    }*/
  }, [id, url, integrity, async, defer, crossOrigin])
}

export default useScript