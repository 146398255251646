import React, { useRef, useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
//import { Form, FormControl } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';


import '../assets/themify-icons/themify-icons.css'

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

//import axios from "axios";

import {
    useQuery,
} from '@tanstack/react-query'

import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { useLocation, useParams } from 'react-router-dom';
import { param } from 'jquery';

const serverURL = process.env.REACT_APP_API_SERVER_URL;

function dateFormatter(params) {
    let dt = params.data.updatedat;
    return dt ? (new Date(dt)).toLocaleString() : '';
}
const TasksView = () => {
    const { getAccessTokenSilently } = useAuth0();
    const gridRef = useRef();
    const [tasksCall, setQueryCall] = useState(0);
    const { idContractor } = useParams();
    const path = useLocation();

    const fetchTasks = async () => {
        try {
            console.log("Getting Tasks Data ");
            const token = await getAccessTokenSilently();
            let resp = await fetch(serverURL + `/api/tasks/list/${idContractor}`, 
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            let tasks = await resp.json();
            //console.log("Listing Tasks - " + JSON.stringify(tasks));
            return tasks;
        } catch (error) {
            console.log(error.message);
            return null;
        };

    }

    const tasks = useQuery({ queryKey: ['tasksCall', tasksCall], queryFn: () => fetchTasks() });

    const doTaskAction = async (id, action) => {
        try {
            console.log("Updating Status Data ", id, action);
            const token = await getAccessTokenSilently();
            const dataJS = { status: action };
            let resp = await fetch(serverURL + `/api/tasks/${idContractor}/${id}`, 
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(dataJS),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });
            let updStatus = await resp.json();
            console.log( "Updated Status - " + JSON.stringify( updStatus ) );
            refresh();
            return tasks;
        } catch (error) {
            console.log(error.message);
            return null;
        };

    }
    const createTask = async ( sTask ) => {
        console.log( "Updating Stage " );
        try {
            console.log("Creating Tasks Stage " );
            const token = await getAccessTokenSilently();
            let resp = await fetch(serverURL + `/api/tasks/${idContractor}/${sTask}`, 
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            let updStatus = await resp.json();
            console.log( "Creating Taks "+ sTask + " - " + JSON.stringify( updStatus ) );
            refresh();
            return tasks;
        } catch (error) {
            console.log(error.message);
            return null;
        };
    };
    const actionsRender = (params) => {
        let id = params.data.id;
        if( params.data.status === 'A' ) {
            return (
                <div>
                    { /* <i onClick={ () => console.log( 'validar' ) } className='ti-reload' data-toggle='tooltip' title='Reprocessar Proposta' ></i>
                    <i onClick={ () => console.log( 'gerar' ) } className='ti-thumb-up  px-1' data-toggle='tooltip' title='Aprovar Proposta' ></i> */ }
                    <i onClick={ () => doTaskAction( params.data.id, 'P' ) } className='ti-control-pause  px-1' data-toggle='tooltip' title='Pausar Tarefa'></i>
                    <i onClick={ () => doTaskAction( params.data.id, 'C' ) } className='ti-na  px-1' data-toggle='tooltip' title='Cancelar Tarefa' ></i>
                    { /* <i onClick={ () => console.log( 'editar' ) } className='ti-pencil-alt  px-1' data-toggle='tooltip' title='Editar Proposta' ></i> */ }
                </div>
            );  
        } else if( params.data.status === 'P' ) {
            return (
                <div>
                    <i onClick={ () => doTaskAction( params.data.id, 'A' ) } className='ti-control-play  px-1' data-toggle='tooltip' title='Ativar Tarefa'></i>
                    <i onClick={ () => doTaskAction( params.data.id, 'C' ) } className='ti-na  px-1' data-toggle='tooltip' title='Cancelar Tarefa' ></i>
                </div>
            );  

        } else {
            return ( <div></div> );
        }  
    };

    const tasksCol = [
        { headerName: 'Ações', cellRenderer: actionsRender, maxWidth: 120 },
        { headerName: 'Id', field: 'id', maxWidth: 70 },
        { headerName: 'Status', field: 'statusdesc', minWidth: 180 },
        { headerName: 'Task', field: 'task', minWidth: 120 },
        { headerName: 'Descrição', field: 'description', maxWidth: 240 },
        { headerName: 'Mensagem', field: 'message', maxWidth: 240 },
        { headerName: 'Ult. Atual.', field: 'updatedat', valueFormatter: dateFormatter,  maxWidth: 240 },
    ]

    const defaultColDef = {
        resizable: true,
        sortable: true
    }


 
    const refresh = async () => {

        console.log("Tasks Refresh");
        setQueryCall( tasksCall + 1);
    }


    console.log("TaskView ", path.pathname );

    return (

        <>


            <Header />
            <Navbar />


            <section id="content" className="content w-100">
                <div className="content__header content__boxed overlapping">
                    <div className="content__wrap">
                        <h1 className="page-title mb-2">Via Varejo - Tarefas </h1>
                        <h2 className="h5">Lista das Últimas Tarefas</h2>
                    </div>
                </div>
                <div className="content__boxed w-100">
                    <div className="content__wrap w-100">
                        <div className="row">
                            <div className="col-xl-12 ">
                                <div className="card  bg-gray-500" style={{ height: "500px" }} >
                                    <div className="card-header d-flex align-items-center border-0">
                                        <div className="me-auto">
                                            <h3 id="chart_title_prop" className="h5 m-0">{tasks.isSuccess ?  "Tarefas": "Tarefas: <Carregando>"}</h3>
                                        </div>
                                        <div id="tb-end" className="toolbar-end">
                                            <button type="button" className="btn btn-icon btn-sm btn-hover btn-light" aria-label="Atualiza Dados" onClick={() => refresh()}>
                                                <i className="ti-reload fs-5"></i>
                                            </button>

                                            <Dropdown title="Ações" id="gen-actions"> 
                                                <Dropdown.Toggle variant="success" className="btn btn-hover btn-light" aria-expanded="false" aria-label="Config Dados" >
                                                    Ações
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu style={{ width: "230px" }} >
                                                    <Dropdown.Header className="fs-5 me-2 dropdown-item" style={{ color: "black" }} >
                                                        Escolha a Ação
                                                    </Dropdown.Header>

                                                    <Dropdown.Item className="dropdown-item" onClick={() => createTask( 'stage' )  } >
                                                        Atualizar Stage
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item" onClick={() => createTask( 'cube' )  } >
                                                        Atualizar Cubo
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item" onClick={() => createTask( 'sendsms' )  } >
                                                        Enviar Ações de SMS
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="dropdown-item" onClick={() => createTask( 'sendrmkt' )  } >
                                                        Enviar SMS de RMKT
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    </div>

                                    <div className="card-body py-0 w-100">
                                        {(tasks.isLoading || tasks.isLoading) &&
                                            <h1 className="h5 m-0">Carregando...</h1>
                                        }
                                        {tasks.isSuccess && tasks.isSuccess &&
                                            <AgGridReact
                                                className="ag-theme-alpine"
                                                ref={gridRef} // Ref for accessing Grid's API
                                                rowData={tasks.data } // Row Data for Rows
                                                columnDefs={ tasksCol } // Column Defs for Columns
                                                efaultColDef={defaultColDef} // Default Col Def for Columns
                                                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                                rowSelection='multiple' // Options - allows click selection of rows
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </section>

        </>
    );
};

export default TasksView;
