import React, { useRef, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
//import { Form, FormControl } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
//import ButtonGroup from 'react-bootstrap/ButtonGroup';
//import $ from 'jquery';
import {  subDays,  format, parse } from 'date-fns'
import moment from 'moment';

import '../assets/themify-icons/themify-icons.css'

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

//import axios from "axios";
//import D3Funnel from 'd3-funnel';

import DateRangeInput from '../components/DateRangeInput';

import {
    useQuery,
    useMutation,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

import Header from "../components/Header";
import Navbar from "../components/Navbar";

const serverURL = process.env.REACT_APP_API_SERVER_URL;


const ViaQuery = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const gridRef = useRef();
    const [queryCall, setQueryCall] = useState(0);
    const [nTotalAprov, setTotalAprov] = useState(0);
    const [nTotalNeg, setTotalNeg] = useState(0);
    const [nTotalErro, setTotalErro] = useState(0);
    const [nTotalProc, setTotalProc] = useState(0);
    const [ dtIni, setDtIni] = useState( new Date() );
    const getFilter = async () => {
        try {
            console.log("Getting Filter Data ");
            const token = await getAccessTokenSilently();
            let resp = await fetch(serverURL + '/api/via/filter', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            let filter = await resp.json();
            console.log("Getting Filter - " + JSON.stringify(filter));
            return filter;
        } catch (error) {
            console.log(error.message);
            return null;
        };

    }

    const filter = useQuery({ queryKey: ['filter'], queryFn: () => getFilter() });
    const viaQuery = useQuery({ queryKey: ['queryCall', queryCall], queryFn: () => fetchViaQuery() });

    const getDescFilter = (_filter) => {
        if (!_filter.arQuery || !_filter.lblRangeDt || !_filter.querySel || _filter.querySel <= 0 || !_filter.arQuery[_filter.querySel - 1]) {
            console.log("Filter.data : ", _filter);
            return 'Consulta: <Sem Filtro>';
        };

        let desc = 'Consulta: ' + _filter.arQuery[_filter.querySel - 1].name + ' - ';

        if (_filter.lblRangeDt && _filter.lblRangeDt !== 'Personalizado') {
            desc += _filter.lblRangeDt;
        } else if (_filter.dtIni && _filter.dtFim) {
            desc += format( parse( _filter.dtIni, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') + ' - ' + format( parse(_filter.dtFim, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
        }
        if (_filter.baseSel)
            desc += ' - Base: ' + _filter.baseSel;
        return desc;
    }
    const defaultColDef = {
        resizable: true,
        sortable: true
    }


    const saveFilter = async (filter) => {
        try {
            const token = await getAccessTokenSilently();
            let resp = await fetch(serverURL + '/api/via/filter', {
                method: 'POST',
                body: JSON.stringify(filter),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
        } catch (error) {
            console.log(error.message);
        };
    };


    const fetchViaQuery = async () => {
        try {
            const token = await getAccessTokenSilently();
            let resp = await fetch(serverURL + '/api/via/dash/query', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            let js = await resp.json();
            return js;
            //setColDef(js.colDef);
            //setData(js.data);
            //console.log( resp );
            //console.log( js );
            //console.log( "ColDef: " + JSON.stringify(js.colDef) );
            //console.log( "Data: " + JSON.stringify(js.data) );

        } catch (error) {
            console.log(error.message);
        };
        //setIsLoading(false);
        //setIsLoaded(true);
    };

    const viaRefresh = async () => {

        console.log("Via Refresh");
        setQueryCall(queryCall + 1);
    }

    const handleChangeDate = async (startDate, endDate, lblRangeDt ) => {
        console.log( "handleChangeDate: ", startDate, endDate, lblRangeDt );
        filter.data.dtIni = format( startDate, 'yyyy-MM-dd');
        filter.data.dtFim = format( endDate, 'yyyy-MM-dd');
        filter.data.lblRangeDt = lblRangeDt;
        await saveFilter({ dtIni: filter.data.dtIni, dtFim: filter.data.dtFim, lblRangeDt: filter.data.lblRangeDt });
        setQueryCall(queryCall + 1);
    }
    const gridDownload = async () => {
        console.log("Grid Download");

        gridRef.current.api.exportDataAsCsv({ filename: 'via-export.csv' });

    }

    const handleFilterBase = async (_baseSel) => {
        console.log("Base Selecionada: " + _baseSel);
        filter.data.baseSel = _baseSel;
        await saveFilter({ baseSel: _baseSel });
        setQueryCall(queryCall + 1);

    };
    const handleQuerySel = async (_querySel) => {
        console.log("Consulta Selecionada: " + _querySel);
        filter.data.querySel = _querySel;
        await saveFilter({ querySel: _querySel });
        setQueryCall(queryCall + 1);
    };

    const handleFilterDt = async (label) => {
        console.log("RangeDt Selecionada: ", label);
        let dtIni = filter.data.dtIni;
        let dtFim = filter.data.dtFim;

        if (label === 'Hoje') {
            dtIni = moment().format('YYYY-MM-DD');
            dtFim = moment().format('YYYY-MM-DD');
        } else if (label === 'Ontem') {
            dtIni = moment().subtract(1, 'days').format('YYYY-MM-DD');
            dtFim = moment().subtract(1, 'days').format('YYYY-MM-DD');
        } else if (label === 'Desde ontem') {
            dtIni = moment().subtract(1, 'days').format('YYYY-MM-DD');
            dtFim = moment().format('YYYY-MM-DD');
        } else if (label === 'Ult. 7 Dias') {
            dtIni = moment().subtract(7, 'days').format('YYYY-MM-DD');
            dtFim = moment().format('YYYY-MM-DD');
        } else if (label === 'Mês Atual') {
            dtIni = moment().startOf('month').format('YYYY-MM-DD');
            dtFim = moment().format('YYYY-MM-DD');
        };
        console.log("DtIni: ", dtIni, " - DtFim: ", dtFim, " - Label: " + label);
        filter.data.lblRangeDt = label;
        filter.data.dtIni = dtIni;
        filter.data.dtFim = dtFim;
        await saveFilter({ dtIni: dtIni, dtFim: dtFim, lblRangeDt: label });
        setQueryCall(queryCall + 1);
    };


    console.log("ViaQuery")
    return (

        <>


            <Header />
            <Navbar />


            <section id="content" className="content w-100">
                <div className="content__header content__boxed overlapping">
                    <div className="content__wrap">
                        <h1 className="page-title mb-2">Via Varejo - Jornada Digital</h1>
                        <h2 className="h5">Painel de Consultas</h2>
                    </div>
                </div>
                <div className="content__boxed w-100">
                    <div className="content__wrap w-100">
                        <div className="row">
                            <div className="col-xl-12 ">
                                <div className="card  bg-gray-500" style={{ height: "500px" }} >
                                    <div className="card-header d-flex align-items-center border-0">
                                        <div className="me-auto">
                                            <h3 id="chart_title_prop" className="h5 m-0">{filter.isSuccess ? getDescFilter(filter.data) : "Consulta: <Carregando>"}</h3>
                                        </div>
                                        <div id="tb-end" className="toolbar-end">
                                            {filter.isSuccess && 
                                                    <DateRangeInput startDate={filter.data.dtIni} endDate={filter.data.dtFim} lblRangeDt={filter.data.lblRangeDt}    onChange={handleChangeDate} />
                                                }
                                            <button type="button" className="btn btn-icon btn-sm btn-hover btn-light" aria-label="Download Tabela" onClick={() => gridDownload()}>
                                                <i className="ti-download fs-5"></i>
                                            </button>
                                            <button type="button" className="btn btn-icon btn-sm btn-hover btn-light" aria-label="Atualiza Dados" onClick={() => viaRefresh()}>
                                                <i className="ti-reload fs-5"></i>
                                            </button>

                                            <Dropdown title="Filtro" id="query-filter1"> { /* className="dropdown-menu dropdown-menu-end" */}
                                                <Dropdown.Toggle variant="success" className="btn btn-hover btn-light" aria-expanded="false" aria-label="Config Dados" >
                                                    Filtro
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu style={{ width: "230px" }} >
                                                    <Dropdown.Header className="fs-5 me-2 dropdown-item" style={{ color: "black" }} >
                                                        Escolha a Consulta
                                                    </Dropdown.Header>

                                                    {filter.isSuccess && filter.data.arQuery.map(query => <Dropdown.Item onClick={() => handleQuerySel(query.id)} active={(filter.data.querySel === query.id)} >{query.name}</Dropdown.Item>)}

                                                    <Dropdown.Divider />
                                                    <Dropdown.Header className="fs-5 me-2 dropdown-item" style={{ color: "black" }} >
                                                        Escolha a Base
                                                    </Dropdown.Header>
                                                    <Dropdown.Item onClick={() => handleFilterBase("Todas")} active={(filter.isSuccess && filter.data.baseSel === 'Todas')}> Todas as Bases
                                                    </Dropdown.Item>

                                                    {filter.isSuccess && filter.data.arBases.map(base => <Dropdown.Item onClick={() => handleQuerySel(base.id)} active={(filter.data.baseSel === base.id)} >{base.name}</Dropdown.Item>)}

                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    </div>
                                    { /* 
                                    <div className="card-body py-0">
                                        <div id="funnel"></div>
                                    </div> */ }
                                    <div className="card-body py-0 w-100">
                                        {(filter.isLoading || viaQuery.isLoading) &&
                                            <h1 className="h5 m-0">Carregando...</h1>
                                        }
                                        {filter.isSuccess && viaQuery.isSuccess && viaQuery.data && viaQuery.data.data && 
                                            <AgGridReact
                                                className="ag-theme-alpine"
                                                ref={gridRef} // Ref for accessing Grid's API
                                                rowData={viaQuery.data.data} // Row Data for Rows
                                                columnDefs={viaQuery.data.colDef} // Column Defs for Columns
                                                efaultColDef={defaultColDef} // Default Col Def for Columns
                                                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                                rowSelection='multiple' // Options - allows click selection of rows
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            { /* <div className="col-xl-4 ">
                                <div className="card  bg-gray-500" style={{ height: "500px" }} >
                                    <div className="card-header d-flex align-items-center border-0">
                                        <div className="me-auto">
                                            <h3 id="chart_title_prop" className="h5 m-0">Por Canal/Hora</h3>
                                        </div>
                                        <div className="toolbar-end">
                                            <button type="button" className="btn btn-icon btn-sm btn-hover btn-light" aria-label="Atualiza Dados" onClick={() => viaRefresh()}>
                                                <i className="ti-reload fs-5"></i>
                                            </button>
                                            <div className="dropdown">
                                                <button className="btn btn-icon btn-sm btn-hover btn-light" data-bs-toggle="dropdown" aria-expanded="false" aria-label="COnfig Dados">
                                                    <i className="demo-pli-dot-horizontal fs-4"></i>
                                                    <span className="visually-hidden">Config Visualização</span>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end" style={{ width: "230px" }} >
                                                    <li>
                                                        <a id="mnu_rpa" href="zzz" className="dropdown-item">
                                                            RPA´s <i id="mnu_rpa_ic" className=" fs-5 me-2"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="mnu_servers" href="aaaa" className="dropdown-item">
                                                            Servidores <i id="mnu_servers_ic" className=" fs-5 me-2"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a id="mnu_origem_todos" href="bbbb" className="dropdown-item">
                                                            Ambos<i id="mnu_origem_todos_ic" className="fs-5 me-2"></i>/
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="card-body py-0">
                                        <div id="periodo"></div>
                                    </div>
                            </div> */ }
                        </div>
                        <div className="row py-2">
                            <div className="col-xl-4">
                                <div className="row g-sm-1 mb-3">
                                    <div className="col-sm-6">
                                        { /* Tile */}
                                        <div className="card bg-secondary text-white mb-1 mb-xl-1" style={{ height: "148px" }}>
                                            <div className="p-3 text-center">
                                                <span id="aprovados_total" className="display-5">{nTotalAprov}</span>
                                                <p>Aprovados</p>
                                                <i className="demo-psi-shopping-bag text-white text-opacity-50 fs-5"></i>
                                            </div>
                                        </div>
                                        { /*END : Tile*/}

                                        { /*Tile*/}
                                        <div className="card bg-warning text-white mb-1 mb-xl-1 " style={{ height: "148px" }}>
                                            <div className="p-3 text-center">
                                                <span id="negados_total" className="display-5">{nTotalNeg} </span>
                                                <p>Negadas</p>
                                                <i className="demo-psi-mail text-white text-opacity-50 fs-5"></i>
                                            </div>
                                        </div>
                                        { /*END : Tile*/}

                                    </div>
                                    <div className="col-sm-6" >

                                        { /*Tile*/}
                                        <div className="card bg-danger text-white mb-1 mb-xl-1" style={{ height: "148px" }}>
                                            <div className="p-3 text-center">
                                                <span id="erro_total" className="display-5">{nTotalErro} </span>
                                                <p>Erros</p>
                                                <i className="demo-psi-coding text-white text-opacity-50 fs-5"></i>
                                            </div>
                                        </div>
                                        { /*END : Tile*/}

                                        { /*Tile*/}
                                        <div className="card bg-info text-white mb-1 mb-xl-1" style={{ height: "148px" }}>
                                            <div className="p-3 text-center">
                                                <span id="fila_total" className="display-5">{nTotalProc}</span>
                                                <p>Processando</p>
                                                <i className="demo-psi-receipt-4 text-white text-opacity-50 fs-5"></i>
                                            </div>
                                        </div>
                                        { /*END : Tile*/}

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card bg-gray-500" style={{ height: "300px" }}>
                                    <canvas id="_via-fila"></canvas>
                                </div>
                            </div>
                            <div className="col-xl-4 ">
                                <div className="card bg-gray-500 py-2 px-2" style={{ height: "300px" }}>
                                    { /* Statistic list */}
                                    <h3>Status</h3>
                                    <ol className="list-group list-group-borderless mb-4">
                                        <li className="list-group-item d-flex justify-content-between align-items-start px-0">
                                            <div className="me-auto">
                                                <div className="text-head fs-5 fw-semibold">% Negadas Dia</div>
                                            </div>
                                            <span className="badge danger</span> fs-5 rounded-pill"> 0% </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

        </>
    );
};

export default ViaQuery;
