import React from "react";
import './App.css';


import { AuthenticationGuard } from "./components/authentication-guard";

import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import ViaQuery  from "./pages/viaQuery";
import TasksView  from "./pages/TasksView";
import ActionsView  from "./pages/ActionsView";


import { Route, Routes } from "react-router-dom";
import Home from './pages';
import ExternalApi from "./pages/ExternalApi.js";
import {
    useQuery,
    useMutation,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query' 

const queryClient = new QueryClient();

export const App = () => {
	const { error } = useAuth0();



	if (error) {
	  return <div>Oops... {error.message}</div>;
	}
  
	//if (isLoading) {
	//	console.log( "isLoading");
	//  return (<PageLoader />);
	//}



	return (
	<QueryClientProvider client={queryClient}>
	<Routes>
		<Route exact path='/'  
		    element={<AuthenticationGuard component={Home} />} />
		<Route path='/via/dashboard'   
		    element={<AuthenticationGuard component={ViaQuery} />} />
		<Route path='/via/tasks/:idContractor'   
		    element={<AuthenticationGuard component={TasksView} />} />
		<Route path='/via/actions/:idContractor'   
		    element={<AuthenticationGuard component={ActionsView} />} />
		<Route path='/callback'   
		    element={<AuthenticationGuard component={Home} />} />
		<Route path='/external-api'   
		    element={<ExternalApi />} />

	</Routes>
	</QueryClientProvider>
);
}

