import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from "../components/logout-buttons";
import useScript from "../hooks/useScript";

import Header from "../components/Header";
import Navbar from "../components/Navbar";

const Home = () => {
	/*const { isAuthenticated, loginWithRedirect } = useAuth0();

	const handleLogin = async () => {
		await loginWithRedirect({
			appState: {
				returnTo: "/about",
			},
			ui_locales: ['pt-BR'],
		});
	}; */
	console.log("App.js - Starting Router");
	useScript('popper', './assets/vendors/popperjs/popper.min.js');
	useScript('bootstrap', './assets/vendors/bootstrap/bootstrap.min.js');
	useScript('nifty', './assets/js/nifty.js');
	useScript('demo', './assets/js/demo-purpose-only.js');
	useScript('chart', './assets/vendors/chart.js/chart.min.js');
	useScript('dashboard', './assets/pages/dashboard-1.js');

	/*
	if (!isAuthenticated) {
		handleLogin();
		return (<div></div>);
	}; */

	return (

		<>
			<Header />
			<Navbar />
			<section id="content" className="content">
				<div className="content__header content__boxed overlapping">
					<div className="content__wrap">

						<h1 className="page-title mb-2">Dashboard</h1>
						<h2 className="h5">Welcome back to the Dashboard.</h2>
						<p>Scroll down to see quick links and overviews of your Server, To do list<br />
							Order status or get some Help using Nifty.</p>

					</div>
				</div>
			</section>
		</>
	);
};

export default Home;
